import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import { push } from 'redux-first-history';

import * as orderActions from '../../../actions/order';
import diningChoiceEnum from '../../../enums/diningChoiceEnum';
import gglocationTypeEnum from '../../../enums/gglocationTypeEnum';
import statusEnum from '../../../enums/statusEnum';
import * as selectors from '../../../sagas/selectors';
import { getIsDiningChoiceDisabled } from '../../../utils/commonUtils';
import { filterAvailableGglocations } from '../../../utils/gglocationUtils';

import Button from '../../Button';
import Delivery from '../Delivery';
import Pickup from '../Pickup';

import './DiningChoiceSelector.css';

function DiningChoiceSelector() {
  const dispatch = useDispatch();

  const isLandingPageOrder = useSelector(selectors.getIsLandingPageOrder);
  const landingPageDisabledDiningChoices = useSelector(
    selectors.getLandingPageDisabledDiningChoices,
  );
  const landingPageDisabledGglocationTypes = useSelector(
    selectors.getLandingPageDisabledGglocationTypes,
  );
  const landingPagePartners = useSelector(selectors.getLandingPagePartners);
  const { gglocations } = useSelector(selectors.getGglocations);
  const { diningChoiceOptions: diningChoiceOptionsStatus } = useSelector(selectors.getOrderStatus);
  const timeSlots = useSelector(selectors.getTimeSlots);

  const isLoggedIn = !!useSelector(selectors.getUserToken);
  const geolocation = useSelector(selectors.getGeolocation);
  const diningChoice = useSelector(selectors.getDiningChoice);
  const diningChoiceOptions = useSelector(selectors.getDiningChoiceOptions);
  const orderGglocationId = useSelector(selectors.getOrderGglocationId);
  const isStationFlow = useSelector(selectors.getIsStationFlow);

  const availableGglocations = useMemo(
    () =>
      filterAvailableGglocations({
        gglocations,
        timeSlots,
        isLandingPageOrder,
        landingPageDisabledGglocationTypes,
        landingPagePartners,
        diningChoice,
      }),
    [
      diningChoice,
      gglocations,
      isLandingPageOrder,
      landingPageDisabledGglocationTypes,
      landingPagePartners,
      timeSlots,
    ],
  );
  const isPickupDisabled = useMemo(
    () =>
      getIsDiningChoiceDisabled({
        diningChoiceOption: diningChoiceOptions.takeAway ? diningChoiceEnum.TAKE_AWAY : null,
        isLandingPageOrder,
        landingPageDisabledDiningChoices,
      }) ||
      availableGglocations.filter(
        (gglocation) => gglocation.gglocationType === gglocationTypeEnum.STORE,
      ).length === 0,
    [
      diningChoiceOptions.takeAway,
      isLandingPageOrder,
      landingPageDisabledDiningChoices,
      availableGglocations,
    ],
  );
  const isStationDisabled = useMemo(
    () =>
      getIsDiningChoiceDisabled({
        diningChoiceOption: diningChoiceOptions.takeAway ? diningChoiceEnum.TAKE_AWAY : null,
        isLandingPageOrder,
        landingPageDisabledDiningChoices,
      }) ||
      availableGglocations.filter(
        (gglocation) => gglocation.gglocationType === gglocationTypeEnum.PARTNER,
      ).length === 0,
    [availableGglocations],
  );
  const isDeliveryDisabled = useMemo(
    () =>
      getIsDiningChoiceDisabled({
        diningChoiceOption: diningChoiceOptions.delivery ? diningChoiceEnum.DELIVERY : null,
        isLandingPageOrder,
        landingPageDisabledDiningChoices,
      }),
    [diningChoiceOptions.delivery, isLandingPageOrder, landingPageDisabledDiningChoices],
  );

  const orderCustomerAddressId = useSelector(selectors.getOrderCustomerAddressId);
  const customerAddressIds = useSelector(selectors.getCustomerAddressIds);

  /* TODO: Rewrite this in a more structured and readable way, we can have something like a
  diningChoiceOptionsPriority array and we just eliminate the disabled ones from it
  and take the first one */

  useEffect(() => {
    if (
      !(isPickupDisabled && isDeliveryDisabled && isStationDisabled) &&
      diningChoiceOptionsStatus === statusEnum.SUCCESS
    ) {
      // Reset dining choice if current dining choice is disabled
      if (isPickupDisabled && diningChoice === diningChoiceEnum.TAKE_AWAY) {
        if (!isStationFlow && isDeliveryDisabled) {
          dispatch(
            orderActions.setDiningChoice({
              diningChoice: diningChoiceEnum.TAKE_AWAY,
              isStationFlow: true,
            }),
          );
        } else if (!isStationFlow) {
          dispatch(orderActions.setDiningChoice({ diningChoice: diningChoiceEnum.DELIVERY }));
        } else {
          dispatch(
            orderActions.setDiningChoice({
              diningChoice: diningChoiceEnum.TAKE_AWAY,
              isStationFlow: true,
            }),
          );
        }
      } else if (isDeliveryDisabled && diningChoice === diningChoiceEnum.DELIVERY) {
        if (!isPickupDisabled) {
          dispatch(orderActions.setDiningChoice({ diningChoice: diningChoiceEnum.TAKE_AWAY }));
        } else if (!isStationDisabled) {
          dispatch(
            orderActions.setDiningChoice({
              diningChoice: diningChoiceEnum.TAKE_AWAY,
              isStationFlow: true,
            }),
          );
        }
      } else if (
        isStationDisabled &&
        diningChoice === diningChoiceEnum.TAKE_AWAY &&
        isStationFlow
      ) {
        if (!isPickupDisabled) {
          dispatch(orderActions.setDiningChoice({ diningChoice: diningChoiceEnum.TAKE_AWAY }));
        } else if (!isDeliveryDisabled) {
          dispatch(orderActions.setDiningChoice({ diningChoice: diningChoiceEnum.DELIVERY }));
        }
      }
    }
  }, [
    isPickupDisabled,
    isDeliveryDisabled,
    isStationDisabled,
    isStationFlow,
    dispatch,
    diningChoice,
    diningChoiceOptionsStatus,
  ]);

  const isDeliveryOrder = diningChoice === diningChoiceEnum.DELIVERY;
  const minimumOrderTotal = diningChoiceOptions?.delivery?.deliveryPartner?.minimumOrderTotal;
  const hasAddresses = customerAddressIds.length > 0;

  const handleGetLocation = () => {
    dispatch(
      push(
        {
          pathname: '/addAddress',
        },
        {
          geolocation,
          zoom: 13,
        },
      ),
    );
  };

  const handleAddressClick = (event) => {
    dispatch(
      push(
        {
          pathname: '/addAddress',
        },
        {
          addressInput: event.target.value,
        },
      ),
    );
  };

  const handlePickupSelect = () => {
    dispatch(orderActions.setDiningChoice({ diningChoice: diningChoiceEnum.TAKE_AWAY }));
  };
  const handleStationSelect = () => {
    dispatch(
      orderActions.setDiningChoice({
        diningChoice: diningChoiceEnum.TAKE_AWAY,
        isStationFlow: true,
      }),
    );
  };
  const handleDeliverySelect = () => {
    dispatch(orderActions.setDiningChoice({ diningChoice: diningChoiceEnum.DELIVERY }));
  };
  let orderLink = { pathname: '/locations' };
  if (orderGglocationId) {
    orderLink = { pathname: '/time' };
  }

  return (
    <div className="DiningChoiceContainer">
      <div className="tabsRow">
        {!isPickupDisabled && (
          <Button
            className={classnames('Button tabsButton', {
              selected: !isDeliveryOrder && !isStationFlow,
            })}
            onClick={handlePickupSelect}
          >
            <Typography variant="subtitle1" fontWeight="800">
              Pickup
            </Typography>
          </Button>
        )}
        {!isStationDisabled && (
          <Button
            className={classnames('Button tabsButton', {
              selected: !isDeliveryOrder && isStationFlow,
            })}
            onClick={handleStationSelect}
          >
            <Typography variant="subtitle1" fontWeight="800">
              Stations
            </Typography>
          </Button>
        )}
        {!isDeliveryDisabled && (
          <Button
            className={classnames('Button tabsButton', { selected: isDeliveryOrder })}
            onClick={handleDeliverySelect}
          >
            <Typography variant="subtitle1" fontWeight="800">
              Delivery
            </Typography>
          </Button>
        )}
      </div>
      {!isDeliveryOrder && <Pickup orderLink={orderLink} />}
      {isDeliveryOrder && (
        <Delivery
          customerAddressId={orderCustomerAddressId}
          minimumOrderTotal={minimumOrderTotal}
          hasAddresses={hasAddresses}
          loggedIn={isLoggedIn}
          onAddressClick={handleAddressClick}
          onGetLocation={handleGetLocation}
        />
      )}
    </div>
  );
}

export default DiningChoiceSelector;
